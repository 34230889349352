import React from "react"
import { Link } from "gatsby"

import { Layout, Seo, MainSectionHeading } from "../layout"

export default function AboutPage(props) {
  return (
    <Layout language="no" path={props.path}>
      <Seo title="About" />
      <main className="tg-main tg-news">
        <MainSectionHeading>Trailguide AS</MainSectionHeading>

        <p className="p-4 sm:p-16 text-xl max-w-4xl mx-auto leading-9">
          Trailguide er et programvareselskap som spesialiserer seg på å utvikle
          innovative løsninger for reiselivsbransjen. Vårt mål er å hjelpe
          reiselivsbedrifter å gi deres gjester og besøkende den absolutt best
          mulige opplevelsen gjennom bruk av teknologi.
        </p>

        <Contact
          cover="bjorn/bjorn-riding.jpg"
          portrait="bjorn/bjorn-portrait-2.jpg"
        >
          <h4 className="p-4 pb-0">BJØRN JARLE KVANDE</h4>
          <p className="px-4 pb-6 text-sm">
            Gründer, daglig leder og hovedutvikler. Synes det beste i verden er
            programmering og blir over seg av begeistring om en del av koden kan
            forbedres, forenkles, eller rett og slett slettes. Liker sykling og
            skikjøring på fritida.{" "}
            <Link to="/coding">
              Jeg driver bloggen Sinnakoder'n her på bruket.
            </Link>
          </p>
        </Contact>

        <Contact
          cover="peter/peter-biking2.jpg"
          portrait="peter/peter-portrait.jpg"
        >
          <h4 className="p-4 pb-0">PETER SEIDL</h4>
          <p className="px-4 pb-6">
            Med-gründer, kartdesigner og utvikler av vår digitale infrastruktur
            for kartlevering. Elsker GIS, 3D-rendring og bygging av systemer med
            løsninger basert på åpen kildekode. Liker alle typer sykling og
            skikjøring på fritida.
          </p>
        </Contact>

        <Contact
          cover="gunther/gunther-aran.jpg"
          portrait="gunther/gunther-riding.jpg"
        >
          <h4 className="p-4 pb-0">GÜNTHER</h4>
          <p className="px-4 pb-6">
            Ansvarlig for transport, kontorlokaler, overnatting og
            markedsføring. En god miks av tysk og italiensk herkomst. Liker å ta
            livet med ro og trives best der det er mektige fjell og daler.
          </p>
        </Contact>
      </main>
    </Layout>
  )
}

function Contact(props = {}) {
  return (
    <section
      className="min-h-screen bg-center bg-no-repeat bg-cover p-8 sm:p-16"
      style={{
        backgroundImage: `url(https://res.cloudinary.com/trailguide-as/image/upload/c_limit,dpr_auto,w_auto:100:1600/v1/${props.cover})`,
      }}
    >
      <div className="w-72 max-w-full mx-auto sm:mx-12 bg-white text-gray-900 shadow-lg rounded border-4 border-white leading-6">
        <img
          alt={props.portrait}
          className="rounded rounded-b-none"
          src={`https://res.cloudinary.com/trailguide-as/image/upload/c_limit,w_400/v1/${props.portrait}`}
        />
        {props.children}
      </div>
    </section>
  )
}
